
p {
  padding: 0;
  margin: 0;
}

.fans_box {
  width: 100%;
  height: calc(100% - 40px);

  .fans_header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 54px;

    .go_back {
      width: 80px;
      height: 32px;
      background: #574ded;
      color: #ffffff;
      border-radius: 4px 4px 4px 4px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .fans_list_box {
    width: 100%;

    background: #ffffff;
    border-radius: 4px 4px 4px 4px;
    padding: 20px;
    box-sizing: border-box;
    // padding-bottom: 50px;

    .fans_title {
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 24px;
      color: #000000;
      margin-bottom: 20px;
    }

    .fans_table {
    }
  }
}

.image_box {
  display: flex;
  align-items: center;

  .image {
    width: 48px;
    height: 48px;
    border-radius: 100%;

    img {
      width: 100%;
      height: 100%;
      border-radius: 100%;
    }
  }

  .nickName {
    margin-left: 10px;
    display: inline-block;
  }
}
